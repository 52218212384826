// @ts-nocheck
import {
  Link as RemixLink, // eslint-disable-next-line import/named
  LinkProps as RemixLinkProps,
} from '@remix-run/react'
import { ComponentProps, forwardRef, useMemo } from 'react'

import {
  checkIfTradepubLink,
  isInsideBrand,
  isURLCurrentBrand,
  useArticleProps,
} from '@scm/ui-core'

const NO_FOLLOW = 'nofollow'
interface IGtm {
  trackGtmClick?: (href: string, e: MouseEvent<HTMLAnchorElement>) => any
  trackPaidContentClick?: (item: any, listName: string, listId: string) => any
}

export type LinkProps = RemixLinkProps & ComponentProps<'a'>

interface CustomLinkProps extends RemixLinkProps, IGtm {}

// eslint-disable-next-line react/display-name
export const Link = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  (
    {
      to = '#',
      trackGtmClick = () => {},
      trackPaidContentClick = () => {},
      ...props
    },
    ref,
  ) => {
    const { noFollow } = useArticleProps()

    const isNormalLink =
      (to as string).startsWith('http') || (to as string).startsWith('mailto:')

    const handleClick = (e: any) => {
      trackGtmClick(to as string, e)
      trackPaidContentClick(to as string, e)
      if (props.onClick) props.onClick(e)
    }

    const rel = useMemo(() => {
      const relationAttribues = props.rel?.split(' ') || []

      if (relationAttribues.includes(NO_FOLLOW)) {
        return props.rel
      }

      if (
        checkIfTradepubLink(to as string) ||
        (noFollow && !isInsideBrand(to))
      ) {
        relationAttribues.push(NO_FOLLOW)
      }

      return relationAttribues.join(' ') || undefined
    }, [props.rel, noFollow])

    return isNormalLink ? (
      // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-no-target-blank
      <a
        {...props}
        onClick={handleClick}
        ref={ref}
        rel={rel}
        target={!isURLCurrentBrand(String(to)) ? '_blank' : '_self'}
        href={to.toString()}
      />
    ) : (
      <RemixLink {...props} onClick={handleClick} to={to} rel={rel} ref={ref} />
    )
  },
)
