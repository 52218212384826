export const BrandDomain = new Set([
  'aibusiness.com',
  'farmprogress.com',
  'informationweek.com',
  'iotworldtoday.com',
  'quantumbusinessnews.com',
  'licenseglobal.com',
  'gamedeveloper.com',
  'darkreading.com',
  'nationalhogfarmer.com',
  'feedstuffs.com',
  'beefmagazine.com',
  'lightreading.com',
  'naturalproductsinsider.com',
  'supplysidesj.com',
  'foodbeverageinsider.com',
  'newhope.com',
  'channelfutures.com',
  'telecoms.com',
  'batterytechonline.com',
  'powderbulksolids.com',
  'designnews.com',
  'packagingdigest.com',
  'plasticstoday.com',
  'mddionline.com',
  'waste360.com',
  'insideselfstorage.com',
  'constructionext.com',
  'bioprocessintl.com',
  '19thcenturyart-facos.com',
  '2023.miff.com.my',
  '20twenties.aviationweek.com',
  '2bmonthly.com',
  '6jg.imasia-passport.com',
  'aam.aviationweek.com',
  'aas19.capaevents.com',
  'aas20.capaevents.com',
  'aas22.capaevents.com',
  'aas23.capaevents.com',
  'abastur.com',
  'ace.aircharterguide.com',
  'ace.speednews.com',
  'acufuel.com',
  'acukwik.com',
  'adma.aviationweek.com',
  'admanufacturing.aviationweek.com',
  'admevents.com.au',
  'admtoronto.com',
  'adprograms.aviationweek.com',
  'adsc2024.smallworldlabs.com',
  'adsupplychain-europe.aviationweek.com',
  'adsupplychain.aviationweek.com',
  'advancedmanufacturingnewyork.com',
  'advancinggenderequality.appliedintelligence.com',
  'aeroenginesasia.aviationweek.com',
  'aerospaceincubator.aviationweek.com',
  'afcom.com',
  'agceuonline.com',
  'agenda.csrsummitoman.com',
  'agenda.csrsummitqatar.com',
  'agenda.csrsummitsaudi.com',
  'agritechtaiwan.com',
  'aibusiness.com',
  'aibusiness.tradepub.com',
  'aibusinessevents.tradepub.com',
  'aiff.net.au',
  'aircraftbluebook.com',
  'airportsolutionsshow.com',
  'akl19.capaevents.com',
  'alchemy.iiris.com',
  'allthingsinnovation.com',
  'allthingsinsights.com',
  'altinvestmentopduediligenceblog.iirusa.com',
  'amas23.capaevents.com',
  'amc.speednews.com',
  'americancityandcounty.tradepub.com',
  'amwc-awards.euromedicom.com',
  'amwc-japan.com',
  'amwc-la.com',
  'amwc-na.com',
  'apas20.capaevents.com',
  'apas21.capaevents.com',
  'api.airportdata.com',
  'apiadmin.vela.penton.com',
  'aplf.imasia-passport.com',
  'aplfa.imasia-passport.com',
  'apmexpo.com',
  'appliedintelligence.informatech.com',
  'apps.cosmoprof-asia.com',
  'apvexpo.com',
  'aquaculturetaiwan.com',
  'aquafisheriesexpo.com',
  'archive.aviationweek.com',
  'arttoronto.ca',
  'asc.speednews.com',
  'aseanbangkok.exhibitions.jewellerynet.com',
  'aseanpaperbangkok.com',
  'asia-arsenal-cfp.blackhat.com',
  'asia-briefings-cfp.blackhat.com',
  'asia-ss-cfp.blackhat.com',
  'asia-trainings-cfp.blackhat.com',
  'asia.seatradecruiseevents.com',
  'asiatechxsg.com',
  'assets.admevents.com.au',
  'assets.australianhydrogenconference.com.au',
  'atxwest.designnews.com',
  'austin.appliedintelligence.live',
  'australianhydrogenconference.com.au',
  'automotive.informatech.com',
  'aviation.informaexhibitions.com',
  'aviationweek.com',
  'avozdaindustria.com.br',
  'awards.informamarketsasia.com',
  'b2bmeeting.foodandhotelindia.com',
  'banktech.com',
  'batterytechonline.tradepub.com',
  'bcaawards.aviationweek.com',
  'beacon.newhope.com',
  'beaconapp.newhope.com',
  'beauty.imv-emarket.com',
  'beautyexpo.com.my',
  'becbm.imasia-passport.com',
  'beton-indonesia.com',
  'bh.gdcvault.com',
  'bioethicstoday.org',
  'bioprocessintl.com',
  'blackhat.com',
  'blackhat.informatech.com',
  'blackhatmea.com',
  'blog.aiff.net.au',
  'blog.chinabeautyexpo.com',
  'blog.decordesignshow.com.au',
  'bodysite.com',
  'broadbandworldnews.com',
  'build4asia.com',
  'buildingindonesia.com',
  'busntruckexpo.com',
  'buy.deltaagdigest.com',
  'buy.feedadditivecompendium.com',
  'ca.imasia-passport.com',
  'callcenter-japan.com',
  'camauto.org',
  'cambuildexpo.com',
  'camwaterexpo.com',
  'canonsubs.com',
  'capalive.capaevents.com',
  'cas19.capaevents.com',
  'catersourcesubs.com',
  'catersourcethespecialevent.informaconnect.com',
  'cats.informa.com',
  'cca.imasia-passport.com',
  'cco.contentmarketinginstitute.com',
  'centreforaviation.com',
  'ceos20.capaevents.com',
  'certificates.blackhat.com',
  'channel.informatech.com',
  'channelevolutioneurope.com',
  'channelfutures.tradepub.com',
  'channelleadershipsummit.com',
  'channelpartnersconference.com',
  'chemnetbase.com',
  'circulation.farmprogress.com',
  'circulation.feedstuffs.com',
  'cmw.informaconnect.com',
  'cn.miff.com.my',
  'commission.vpico.com',
  'conahp.org.br',
  'conference.adma.aviationweek.com',
  'conference.admfg.aviationweek.com',
  'conference.adp.aviationweek.com',
  'conference.adrmc.aviationweek.com',
  'conference.adsc.aviationweek.com',
  'conference.adsupplychain-europe.aviationweek.com',
  'conference.aeroengineconference.com',
  'conference.aeroenginesusa.com',
  'conference.aerospaceit.aviationweek.com',
  'conference.engineleasingandfinance-europe.com',
  'conference.gad-america.aviationweek.com',
  'conference.mroaustralasia.aviationweek.com',
  'conference.mrolatinamerica.aviationweek.com',
  'conference.mromiddleeast.aviationweek.com',
  'conference.nbjsummit.com',
  'conference.nutritioncapital.com',
  'conference.speednewsamc.aviationweek.com',
  'conference.speednewscasc.aviationweek.com',
  'conference.speednewsrmc.aviationweek.com',
  'connect.informamarkets.com',
  'connect.naturalproductsexpo.com',
  'connect.supplysideshow.com',
  'connect.thinkhdi.com',
  'connectingafrica.com',
  'connections.fimeshow.com',
  'connectsubs.com',
  'constructionext.com',
  'constructionindo.com',
  'consumerdigitalhealthblog.iirusa.com',
  'content.tech',
  'contentmarketinginstitute.com',
  'contentmarketinguniversity.com',
  'convenienceretailing.com',
  'coolkidsfashion.com',
  'corporatetravelcommunity.com',
  'cosmoprof.com',
  'coteriefashionevents.com',
  'cphik.imasia-passport.com',
  'create.informaconnect.com',
  'create.nrn.com',
  'createsubs.com',
  'createyournextcustomer.com',
  'creative-circuit.co',
  'cspdigitals.com',
  'cstorewomen.com',
  'cstse2024.smallworldlabs.com',
  'darkreading.tradepub.com',
  'darkreadingnewsletter.tradepub.com',
  'datacenterevolve.com',
  'datacenterknowledge.tradepub.com',
  'datacenterworld.com',
  'datacenterworld.informatech.com',
  'decordesignshow.com.au',
  'deepfest.com',
  'delhi.jewelleryfair.in',
  'designnews.tradepub.com',
  'dev-penton-sponsor-tool-php-8.pantheonsite.io',
  'dev-sponsorship-opportunities-php-8.pantheonsite.io',
  'dev.lcaexecutivesummit.com',
  'developers.iiris.com',
  'devtest.ajobonline.com',
  'digital-transformation.aviationweek.com',
  'digital.agrishow.com.br',
  'digital.concreteshow.com.br',
  'digital.feirafutureprint.com.br',
  'digital.formobile.com.br',
  'digital.futurecom.com.br',
  'digital.hospitalar.com',
  'digital.intermodal.com.br',
  'digital.seatrade-cruise.com',
  'digital.seatrade-maritime.com',
  'digitaltveurope.tradepub.com',
  'directory.designnews.com',
  'directory.facilitiesshow.co.uk',
  'directory.ifsecglobal.com',
  'directory.packagingdigest.com',
  'directory.powderbulksolids.com',
  'directory.qmed.com',
  'directory.shponline.co.uk',
  'dmhcauthoring.informabi.com',
  'docsaem.informamarkets.com',
  'drdobbs.com',
  'dts2023.smallworldlabs.com',
  'east.supplysideshow.com',
  'eesubs.com',
  'egisec.org',
  'electricvietnam.com',
  'elevatingfounders.tech',
  'emcsoft.euromedicom.com',
  'en.cloudconnectevent.cn',
  'en.hotelex.cn',
  'en.insecworld.com',
  'en.oceanexpo.com.cn',
  'en.zuchexcairo.com',
  'energisingtheindustry.com',
  'energy-utilities.com',
  'energy.imv-emarket.com',
  'enewspro.penton.com',
  'engineleasingandfinance-europe.com',
  'englishhistoricaldocuments.com',
  'ent-design.com',
  'enterpriseconnect.com',
  'enterpriseconnect.informatech.com',
  'enterpriseconnect.tradepub.com',
  'epay.advanstar.com',
  'ers.ubmthailand.com',
  'eurogin-order.com',
  'euromedicom.org',
  'europe-arsenal-cfp.blackhat.com',
  'europe-briefings-cfp.blackhat.com',
  'europe-cfp.blackhat.com',
  'europe-ss-cfp.blackhat.com',
  'europe-trainings-cfp.blackhat.com',
  'europe.cphi.com',
  'evasia-expo.com',
  'event.interop.com',
  'event.powderbulksolids.com',
  'eventexp.com',
  'events.aviationweek.com',
  'events.informamarkets.com',
  'events.penton.com',
  'evmasia.com',
  'exhibit.thespecialeventshow.com',
  'exhibitions.jewellerynet.com',
  'exhibitor-portal.euromedicom.com',
  'exhibitor.expoeast.com',
  'exhibitor.expowest.com',
  'exhibitor.healthcarewasteconf.com',
  'exhibitor.mroamericas.aviationweek.com',
  'exhibitor.mroasia.aviationweek.com',
  'exhibitor.mrobeer.aviationweek.com',
  'exhibitor.mroeurope.aviationweek.com',
  'exhibitor.supplysideeast.com',
  'exhibitor.supplysidewest.com',
  'exhibitor.wasteexpo.com',
  'exhibitors.cphi.com',
  'exhibitors.decorex.com',
  'exhibitors.energy-utilities.com',
  'exhibitors.facilitiesshow.com',
  'exhibitors.figlobal.com',
  'exhibitors.firex.co.uk',
  'exhibitors.informamarkets-info.com',
  'exhibitors.kbb.co.uk',
  'exhibitors.safety-health-expo.co.uk',
  'exhibitors.sleepandeatevent.com',
  'exhibitreg.fashionresource.com',
  'expocihac.com',
  'expomanufactura.com.mx',
  'expomed.com.mx',
  'expoproduccion.mx',
  'f1000research.com',
  'f1research.com',
  'facilitiesshowindia.com',
  'fall-trainings-cft.blackhat.com',
  'fanexpohq.com',
  'fhafnb.com',
  'fhahoreca.com',
  'fhi.imasia-passport.com',
  'fhm.imasia-passport.com',
  'fht.imasia-passport.com',
  'fhtbali.com',
  'fhtshow.com',
  'fia.imasia-passport.com',
  'financialintelligence.informa.com',
  'finovate.com',
  'fintechfuturesjobs.com',
  'fjyhk.imasia-passport.com',
  'folklore-society.com',
  'food-restaurant.knect365.com',
  'foodandhotel.com',
  'foodhotel.imv-emarket.com',
  'foodnhotelasia.com',
  'foodnhotelhanoi.com',
  'foodnhotelvietnam.com',
  'forum.saudebusiness.com',
  'fstec.com',
  'ftp.aestheticshow.com',
  'gad-americas.aviationweek.com',
  'gamechoiceawards.com',
  'gamedeveloper.com',
  'gamedeveloper.tradepub.com',
  'gatesopenresearch.org',
  'gdc.informatech.com',
  'gdconf.com',
  'gdcsubs.com',
  'gdcvault.com',
  'geo-asia.com',
  'get.informaconnect.com',
  'get.knect365.com',
  'globalfinance.connectmeinforma.com',
  'globalportoperations.com',
  'globalrlc.com',
  'greekshippingawards.gr',
  'greenbuild.connectmeinforma.com',
  'greenbuild.informaconnect.com',
  'group-portal.euromedicom.com',
  'h2expohidrogeno.mx',
  'hbcp.chemnetbase.com',
  'hbcponline.com',
  'hdisubs.com',
  'health-box.com.au',
  'healthopenresearch.org',
  'hempproductsconnect.com',
  'hi-korea.net',
  'hijapan.info',
  'his.saudebusiness.com',
  'hk23.corporatetravelcommunity.com',
  'hlthcp.com',
  'hometexexpo.com',
  'hoperatingscale.com',
  'hospitalitysummit.com.mx',
  'hotelexindonesia.com',
  'hotelexpoindonesia.com',
  'hrbopenresearch.org',
  'hrpresentations.arabhealthrecruitment.com',
  'hub.iiris.com',
  'hvacrvietnam.com',
  'hyderabad.jewelleryfair.in',
  'icmi.jp',
  'icmi.tradepub.com',
  'id.informamarkets.com',
  'iecie.imasia-passport.com',
  'ifeshanghai.com',
  'iframe.euromedicom.com',
  'ifsecandfirexegypt.com',
  'ifsecindia-awards.com',
  'igf.com',
  'ilabinsight2.com',
  'imconlinereg.com',
  'imi.connectmeinforma.com',
  'imjhk.imasia-passport.com',
  'imxlive.informamarkets.com',
  'indonesiainfrastructureweek.com',
  'industrial.theaisummit.com',
  'inflavourexpo.com',
  'informa-mea.com',
  'informa.tradepub.com',
  'informaconnect.com',
  'informahealthandnutrition.com',
  'informamarketsjapan.com',
  'informarail.com.au',
  'informatech.tradepub.com',
  'informationweek.tradepub.com',
  'infrastructureconnect.id',
  'inmexvietnam.com',
  'innopackfood.com',
  'innovation-academy.co.uk',
  'innovationexperience2023.smallworldlabs.com',
  'insideetfsaustralia.com.au',
  'insightimmersionnyc.com',
  'insights.blackhatmea.com',
  'insights.figlobal.com',
  'insights.omnia-health.com',
  'insurancetech.com',
  'interiordesignshow.com',
  'interiordesignshowwest.com',
  'intermachshow.com',
  'internationalrelations-publishing.org',
  'interop.com',
  'interop.tradepub.com',
  'iotworldtoday.tradepub.com',
  'issashowindia.com',
  'issashowplanner.com',
  'itprotoday.tradepub.com',
  'iwce.informatech.com',
  'iwceexpo.com',
  'jewellery.jewellerynet.com',
  'jewellerygemaseanbkk.com',
  'jewellerynet.com',
  'jga.exhibitions.jewellerynet.com',
  'jgw.exhibitions.jewellerynet.com',
  'knowidinhf.com',
  'komatekfuar.com',
  'laas19.capaevents.com',
  'laas20.capaevents.com',
  'lab.euromedicom.com',
  'label-expo.com',
  'labelexposoutheastasia.com',
  'laobuild.com',
  'laofoodhotel.com',
  'lasvegasfeb.fashionresource.com',
  'laureates.aviationweek.com',
  'lclh19.capaevents.com',
  'lead-scan.imsharecenter.com',
  'leaders.afcom.com',
  'leadersin.com',
  'leadingcaterers.com',
  'leadinsights.informa.com',
  'library.workspace-connect.com',
  'lifesciences.connectmeinforma.com',
  'live.omnia-health.com',
  'livestocktaiwan.com',
  'lloydslistaustralia.com.au',
  'logisticsawards.co.uk',
  'london.theaisummit.com',
  'londontechweek.com',
  'lp.licenseglobal.com',
  'madsconference.com',
  'magazine.bioprocessintl.com',
  'malms.aviationweek.com',
  'manufacturing.imv-emarket.com',
  'marketing.farmprogress.com',
  'marketplace.aviationweek.com',
  'marketreadyinsights.com',
  'mastersofpediatrics.com',
  'materialsopenresearch.org',
  'mddionline.tradepub.com',
  'mededpublish.org',
  'mediceastafrica.com',
  'medtecjapan.com',
  'meet.beautyspacevirtual.com',
  'meetingsgroup.connectmeinforma.com',
  'meetthebuyers.net',
  'menudirections.com',
  'menumastersevent.com',
  'meos-geo.com',
  'metaltech.com.my',
  'mexicowindpower.com.mx',
  'mfamonitor.com',
  'miff.imasia-passport.com',
  'mining.imv-emarket.com',
  'miningvietnam.com',
  'mira-event.com',
  'molecularpsychology.org',
  'monacoyachtshow.com',
  'motorcycleshows.com',
  'mroamericas.aviationweek.com',
  'mroamericas2024.smallworldlabs.com',
  'mroasia.aviationweek.com',
  'mroaustralasia.aviationweek.com',
  'mrobeer.aviationweek.com',
  'mroeurope.aviationweek.com',
  'mroevents.aviationweek.com',
  'mrolatinamerica.aviationweek.com',
  'mromiddleeast.aviationweek.com',
  'mromiddleeast2024.smallworldlabs.com',
  'mrosoutheastasia.aviationweek.com',
  'mrotransatlantic.aviationweek.com',
  'ms.jjgle.com',
  'mtahanoi.com',
  'mtavietnam.com',
  'mundodoplastico.plasticobrasil.com.br',
  'myanwater.com',
  'nationaldisabilitysummit.com.au',
  'nationalpolicyseries.com.au',
  'naturalproductspopups.com',
  'ne.jlclive.com',
  'netline.com',
  'networkcomputing.tradepub.com',
  'networkx.informatech.com',
  'networkxevent.com',
  'news.asiatechxsg.com',
  'newyork.theaisummit.com',
  'newyorkfeb.fashionresource.com',
  'nextforecast.com',
  'nextmoveegypt.com',
  'nitrosamineimpurities.com',
  'nojitter.tradepub.com',
  'ntresourcesweek.com.au',
  'nws.euromedicom.com',
  'nywomensfashionevent.com',
  'nywomensfashionevents.com',
  'october.istanbuljewelryshow.com',
  'oga.imasia-passport.com',
  'omdia.tech.informa.com',
  'omdia.tradepub.com',
  'onegiantleap.com',
  'oneofakindshow.com',
  'online.cphifrankfurt.com',
  'openresearchafrica.org',
  'openresearchcentral.org',
  'opp-admin.cmpi.net',
  'opp2.cmpi.net',
  'opps.informamarkets.com',
  'oshbangladesh.com',
  'outlookleadership.com',
  'packaging.imv-emarket.com',
  'packagingdigest.tradepub.com',
  'partneringone.informaconnect.com',
  'pbstexas.com',
  'pharmaeandl.com',
  'pharmaipr-india.com',
  'pharmalytica.in',
  'pharmaregulationindia.com',
  'philauto.org',
  'philbeautyshow.com',
  'pilbarasummit.com.au',
  'placementtracker.com',
  'plansmartgrowsmart.com',
  'plasticstoday.tradepub.com',
  'plasticsvietnam.com',
  'plastimagen.com.mx',
  'pm.euromedicom.com',
  'powderandbulkshow.com',
  'powderbulksolids.tradepub.com',
  'ppka.imasia-passport.com',
  'premierecolumbusshow.biz',
  'premiereorlandoshow.biz',
  'presentations.informa-mea.com',
  'pri.imasia-passport.com',
  'prod.lcaexecutivesummit.com',
  'prod.licenseglobal.com',
  'project-tokyo.com',
  'propakasia.com',
  'propakconnect.com',
  'propakindia.com',
  'propakindonesia.com',
  'propakmyanmar.com',
  'propakvietnam.com',
  'psn.fi.informais.jp',
  'pulse.ovum.com',
  'pumpsandvalves-asia.com',
  'pwsg.imasia-passport.com',
  'qatar20.capaevents.com',
  'qmed.com',
  'qmed.tradepub.com',
  'quantumbusiness.org',
  'quantumbusinessnews.tradepub.com',
  'radio.farmprogressamerica.com',
  'rbga.imasia-passport.com',
  'reframingphotography.com',
  'reg.denggle.com',
  'reg.fashionresource.com',
  'reg.foodnhotelvietnam.com',
  'register.informamarkets-info.com',
  'registration.foodnhotelasia.com',
  'renaldrugdatabase.co.uk',
  'renaldrugdatabase.com',
  'renergyvietnam.com',
  'renew.aviationweek.com',
  'renewableenergyindiaexpo.com',
  'renewableenergyindonesia.com',
  'renzulliscales.com',
  'resources.iiris.com',
  'response.informamarketsasia.com',
  'restaurant-food.informaconnect.com',
  'restaurantleadership.com',
  'rmc.speednews.com',
  'routledgehandbooks.com',
  'routledgeresearchencyclopedias.com',
  'routledgesoc.com',
  'routledgeteachertraininghub.com',
  'rp.tandfonline.com',
  'saharaexpo.com',
  'saladplate.imasia-passport.com',
  'sampleroom.online',
  'satte.in',
  'saudi-eventshow.com',
  'sdcongress.com',
  'seasonsautumn.exhibitions.jewellerynet.com',
  'seasonsspring.exhibitions.jewellerynet.com',
  'seconexpo.com',
  'sector-arsenal-cfp.blackhat.com',
  'sector-ss-cfp.blackhat.com',
  'sector-trainings-cfp.blackhat.com',
  'sector.informatech.com',
  'sectorcfp.blackhat.com',
  'secure.icmi.com',
  'securitybootcamp.informatech.com',
  'seoulfoodnhotel.co.kr',
  'seoulfoodnhotel.com',
  'sfh.imasia-passport.com',
  'shop.aircharterguide.com',
  'shownews.aviationweek.com',
  'shponline.co.uk',
  'sing23.corporatetravelcommunity.com',
  'siuf.com',
  'skyview.aero',
  'smhinfrastructure.com.au',
  'smhschools.com.au',
  'smw.informatech.com',
  'soc-for-ed-studies.org.uk',
  'solutions.farmprogress.com',
  'solutions.foodbeverageinsider.com',
  'solutions.informaconstructionmarkets.com',
  'solutions.informastrategicmarkets.com',
  'solutions.licenseglobal.com',
  'solutions.naturalproductsinsider.com',
  'solutions.newhope.com',
  'spayment-admin.blackhatmea.com',
  'sponsorlogo.informamarkets.com',
  'sponsorship.fi-europe.com',
  'springinvestormeeting23.smallworldlabs.com',
  'standards.newhope.com',
  'storageandsmartgridblog.iirusa.com',
  'store.cmpgame.com',
  'store.newhope.com',
  'stream1.informalive.com.au',
  'streamly.video',
  'styleadvisor.com',
  'sub.aircraftbluebook.com',
  'sub.aviationweek.com',
  'subawin.aviationweek.com',
  'subconthailand.com',
  'supplyside365.com',
  'supplysideeast23.smallworldlabs.com',
  'supportworld.informatech.com',
  'swapcard.euromedicom.com',
  'sydney19.capaevents.com',
  'tactyc.org.uk',
  'tahaluf.com',
  'tandfeditingservices.cn',
  'tandfeditingservices.com',
  'tarsus.mx',
  'taylorandfrancis-renewals.informa.com',
  'taylorandfrancis.com',
  'tbivision.com',
  'tbivision.tradepub.com',
  'teachingandlearningplaybook.com',
  'techlibrary.tradepub.com',
  'technomicpanel.com',
  'telcotransformation.com',
  'telecoms.com',
  'telecomstechacademy.com',
  'tensubs.com',
  'testapedia.com',
  'thai-water.com',
  'theageinfrastructure.com.au',
  'theageschools.com.au',
  'theaiconics.com',
  'theartistproject.com',
  'thebatteryshow.com',
  'thebrideshow.com',
  'thegreenexpo.com.mx',
  'themapmeeting.com',
  'themspsummit.com',
  'thinkhdi.tradepub.com',
  'tmt.knect365.com',
  'toddwhitakerroutledge.com',
  'togo-iryo.jp',
  'top200.nrn.com',
  'training.telecomstechacademy.com',
  'trainings-im.com',
  'tsnn.com',
  'uamap.aviationweek.com',
  'uameurope.aviationweek.com',
  'uat.lcaexecutivesummit.com',
  'ubm-info.com',
  'ubmconlinereg.com',
  'urgentcomm.com',
  'urgentcomm.tradepub.com',
  'usa-arsenal-cfp.blackhat.com',
  'usa-briefings-cfp.blackhat.com',
  'usa-cfp.blackhat.com',
  'usa-ss-cfp.blackhat.com',
  'usa-trainings-cfp.blackhat.com',
  'vapefair.id',
  'vfa.imasia-passport.com',
  'vietstock.org',
  'vietwater.com',
  'visitor-registration.cbmeindia.com',
  'wallstreetandtech.com',
  'wardsauto.informa.com',
  'wardsauto.tradepub.com',
  'wardsintelligence.com',
  'wardsintelligence.informa.com',
  'was19.capaevents.com',
  'was20.capaevents.com',
  'was22.capaevents.com',
  'was23.capaevents.com',
  'wasteexpo2024.smallworldlabs.com',
  'water.imv-emarket.com',
  'waterindonesiaexpo.com',
  'webinar.aviationweek.com',
  'webinar.enterpriseconnect.com',
  'west.supplysideshow.com',
  'wetvexpo.com',
  'woc-india.com',
  'wofex.com',
  'workshoppsr.ctee.com.br',
  'workspace-connect.com',
  'worldbroadbandassociation.com',
  'wsc-resources.workspace-connect.com',
  'wtv.imasia-passport.com',
  '51jkexpo.com',
  'a4m.com',
  'abastur.com',
  'abexpo.com',
  'abfexpo.com.br',
  'abudhabispacedebate.com',
  'aclechina.com',
  'acrex.in',
  'acukwikalert.com',
  'adhesivesandbondingexpo-europe.com',
  'adhesivesandbondingexpo.com',
  'admmontreal.com',
  'admtoronto.com',
  'adsbyinforma.com.br',
  'advancedmanufacturingeast.com',
  'advancedmanufacturingminneapolis.com',
  'advancedmanufacturingnewyork.com',
  'aeroengineconference.com',
  'aeroenginesusa.com',
  'aerospacesummit.com',
  'aestheticshow.com',
  'africa-energyexpo.com',
  'africa-foodmanufacturing.com',
  'africahealthexhibition.com',
  'ageinnovation.com.au',
  'agendasetorial.com.br',
  'agriculturedive.com',
  'agrishow.com.br',
  'agritechtaiwan.com',
  'aime.aero',
  'aircharterguide.com',
  'airecweek.com',
  'airlinecxo.aero',
  'airportdata.com',
  'airportsolutions.com',
  'americancityandcounty.com',
  'amwc-asia.com',
  'amwc-conference.com',
  'amwc-japan.com',
  'amwc-na.com',
  'amwcamericas.com',
  'amwcbrazil.com.br',
  'aplf.com',
  'appliedintelligence.com',
  'apvexpo.com',
  'aquaartmiami.com',
  'aquatechtrade.com',
  'arabhealthonline.com',
  'arqsmartconstruction.com',
  'artmiami.com',
  'artpbfair.com',
  'artwynwood.com',
  'asansoristanbul.com',
  'aseanmne.com',
  'asew-expo.com',
  'asiandefense.com',
  'asiaphotonicsexpo.com',
  'asiawater.org',
  'asm-global.com',
  'atxwest.com',
  'autoaftermarketexpos.com',
  'automationexchange.com',
  'automech-formula.com',
  'automotivedive.com',
  'autotechegypt.com',
  'bankingdive.com',
  'bariship.com',
  'beautyexpo.com.my',
  'beautylifebonanza.com',
  'beautyspacevirtual.com',
  'beefmagazine.com',
  'beyondbeautyparis.com',
  'bio-st.com',
  'biomedboston.com',
  'biomedeviceevents.com',
  'biomedevicesiliconvalley.com',
  'biopharmaconclave.com',
  'biopharmadive.com',
  'blackhat.com',
  'blackhatmea.com',
  'boatshowchina.com',
  'boatshowmarketplace.com',
  'boilex-asia.com',
  'brainweek.org',
  'brandlicensing.eu',
  'brandlicensinginnovationsummit.com',
  'brandprint-china.com',
  'brandprint-expo.com',
  'brandprint-india.com',
  'brazilenergyweek.com.br',
  'brazilwindpower.com.br',
  'build4asia.com',
  'buildexshows.com',
  'buildshowlive.com',
  'callcenter-japan.com',
  'canalenergia.com.br',
  'cardiometabolichealth.org',
  'care-show.com',
  'catersource.com',
  'cbmeindia.com',
  'cbmeturkey.com',
  'cbmeturkeyconnect.com',
  'cbmeturkiye.com.tr',
  'cbmexpo.com',
  'ceramicsexpousa.com',
  'cfo.com',
  'cfodive.com',
  'channelfutures.com',
  'chemistanddruggist.co.uk',
  'chemistanddruggistjobs.co.uk',
  'childrensfashionevents.com',
  'chinabeautyexpo.com',
  'chinacleanexpo.com',
  'ciodive.com',
  'cisie.cn',
  'cityscape-intelligence.com',
  'cityscapebahrain.com',
  'cityscapeegypt.com',
  'cityscapeglobal.com',
  'cityscapeonline.com',
  'cityscapeqatar.com',
  'cleanshowistanbul.com',
  'cloudconnectevent.cn',
  'cmashippingevent.com',
  'coldmeattaiwan.com',
  'commonwealthroundtable.co.uk',
  'concreteshow.com.br',
  'concreteshowseasia.com',
  'conexiones365.com',
  'connectechasia.com',
  'connectingafrica.com',
  'connectmeetings.com',
  'connectvirtualcare.com.au',
  'connexion.cn',
  'constructiondive.com',
  'constructionext.com',
  'constructionindo.com',
  'constructshow.com',
  'contentmarketingawards.com',
  'contentmarketingworld.com',
  'contextartmiami.com',
  'cosmobeauteasia.com',
  'cosmoprof-asia.com',
  'cosmoprof.com',
  'cosmoprofcbeasean.com',
  'cosmoprofindia.com',
  'cottm-online.com',
  'cottm.com',
  'cphi-china.cn',
  'cphi-online.com',
  'cphi.cn',
  'cphi.com',
  'cphijapan.com',
  'crew-connect-global.com',
  'cspdailynews.com',
  'cstoredive.com',
  'customerexperiencedive.com',
  'cybersec.aero',
  'cybersecuritydive.com',
  'darkreading.com',
  'datacenterknowledge.com',
  'decorex.com',
  'deepfest.com',
  'dentech.com.cn',
  'designaccess-fa.com',
  'designcon.com',
  'designnews.com',
  'destinationfashionevents.com',
  'dhacademy.com.br',
  'dietandbeauty.jp',
  'digitalhealthacademy.com.br',
  'digitaltveurope.com',
  'diveapi.com',
  'dmwest.com',
  'dovepress.com',
  'drinktechindonesia.com',
  'dubaiairshow.aero',
  'dubaimaritimeagenda.com',
  'dubaimaritimesummit.com',
  'ebdgroup.com',
  'eca-energy.com',
  'ecfair.jp',
  'educationdive.com',
  'eecera.org',
  'egypt-energy.com',
  'electricindonesia.com',
  'electricxegypt.com',
  'elexcon.com',
  'en-sjgle.com',
  'enase.com.br',
  'encontromercadolivre.com.br',
  'energisingtheindustry.com',
  'energysolutionsshow.com.br',
  'enertecasia.com',
  'engineeringwk.com',
  'engineleasingandfinance-europe.com',
  'escabona.com',
  'eurogin.com',
  'euromedicom.com',
  'europaworld.com',
  'evtechexpo.com',
  'evtechexpo.eu',
  'exhibitors.datacenterworld.com',
  'expocihac.com',
  'expoeast.com',
  'expolifestyle.com',
  'expomafe.com.br',
  'expomaritt.com',
  'expomed.com.mx',
  'expowest.com',
  'faceconference.com',
  'facilitiesdive.com',
  'facilitiesshow.com',
  'farmfuturessummit.com',
  'farmprogress.com',
  'farmprogressshow.com',
  'fashionaccess.aplf.com',
  'fashiondive.com',
  'fashionframeworks.com',
  'feedstuffs.com',
  'feimec.com.br',
  'feirafutureprint.com.br',
  'fenalaw.com.br',
  'fggle.com',
  'fhafnb.com',
  'fhcchina.com',
  'fhihoreca.com',
  'fhtbali.com',
  'fhtevent.com',
  'fi-events.com.br',
  'fia-china.com',
  'figlobal.com',
  'fimeshow.com',
  'findfashionevents.com',
  'findmanufacturingbuyers.com',
  'fintechfutures.com',
  'firex.co.uk',
  'firexegypt.com',
  'fispalcafe.com.br',
  'fispalfoodservice.com.br',
  'fispalsorvetes.com.br',
  'fispaltecnologia.com.br',
  'flibs.com',
  'flowershow.com.tr',
  'foam-expo-china.com',
  'foam-expo-europe.com',
  'foam-expo.com',
  'food-management.com',
  'foodandhotel.com',
  'foodandhotelmyanmar.com',
  'foodbeverageinsider.com',
  'foodconnection.com.br',
  'fooddive.com',
  'foodhospitalityindonesia.com',
  'foodhotelindonesia.com',
  'foodpharmaconnect.com',
  'foodservicedirector.com',
  'formobile.com.br',
  'foundermade.com',
  'francelicensingday.com',
  'fsa-expo.com',
  'furniture-china.cn',
  'futurecom.com.br',
  'futuronamesa.com.br',
  'gamedeveloper.com',
  'gess-turkiye.com',
  'gessasia.com',
  'gessawards.com',
  'gessdubai.com',
  'gesseducation.com',
  'gessleaders.com',
  'gessmexico.com',
  'gifa-indonesia.com',
  'giftedscales.com',
  'global-netzero.com',
  'globalhealthsaudi.com',
  'globalspacecongress.com',
  'goldenmousetraps.com',
  'grocerydive.com',
  'growmach.com',
  'growtech.com.tr',
  'gulfprintpack.com',
  'hdeexpo.com',
  'hdiconference.com',
  'healthcaredive.com',
  'healthcarewasteconf.com',
  'hepexpo.com',
  'highereddive.com',
  'hijapan.info',
  'hncexpo.com',
  'hofex.com',
  'hopeconsortiumevent.com',
  'hospitalar.com',
  'hospitalitysummit.com.mx',
  'hostistanbulfair.com',
  'hoteldive.com',
  'hotelex.cn',
  'housewaresevents.com',
  'hrdesign.cn',
  'hrdive.com',
  'huskerharvestdays.com',
  'hvacrseries.com',
  'iasgp.org',
  'icad-conference.com',
  'iecie.com',
  'ifsec.events',
  'ifsecglobal.com',
  'ifsecindia.com',
  'im-aesthetics.com',
  'imeeventscalendar.com',
  'imengineeringeast.com',
  'imengineeringsouth.com',
  'imengineeringwest.com',
  'imewestplus.com',
  'imhx.net',
  'imsinoexpo.com',
  'indiacruiseconference.com',
  'indiahealth-exhibition.com',
  'indiahealth-expo.com',
  'indiahealth-expo.in',
  'indiapackagingawards.com',
  'indiapharmaawards.com',
  'industrydive.com',
  'informa-mea.com',
  'informa.com',
  'informa.com.au',
  'informaconnect.com.sg',
  'informaeds.com',
  'informagic.com',
  'informagm.com',
  'informamarkets.cn',
  'informamarkets.com',
  'informamarketstaiwan.com',
  'informasubscriptions.com',
  'informationweek.com',
  'ingredientsnetwork.com',
  'inmex-china.com',
  'inmex-smm-india.com',
  'innopack-india.com',
  'insecworld.com',
  'insideselfstorage.com',
  'intelligentbuildingeurope.com',
  'intermachshow.com',
  'intermodal-asia.com',
  'intermodal-events.com',
  'intermodal.com.br',
  'intermodalsummit.com.br',
  'intlsurfaceevent.com',
  'iotworldtoday.com',
  'ispesingapore.org',
  'issashow.com',
  'issworldexpo.com',
  'istanbuljewelryshow.com',
  'istanbulkidsfashion.com',
  'istanbullight.com',
  'itehcmc.com',
  'itprotoday.com',
  'japanjewelleryfair.com',
  'japanlifescienceweek.com',
  'jewelleryarabia.com',
  'jewellerygemaseanbkk.com',
  'jewellerygemdigital.com',
  'jgtdubaijewelleryshow.com',
  'jlclive.com',
  'juniorallstar.com',
  'jwadubai.com',
  'jwawards.com',
  'k12dive.com',
  'kbb.co.uk',
  'kenko-media.com',
  'lab-asia.com',
  'lab-indo.com',
  'labelawards.com',
  'labelexpo-americas.com',
  'labelexpo-asia.com',
  'labelexpo-europe.com',
  'labelexpo-india.com',
  'labelexpo-mexico.com',
  'labelexpo-seasia.com',
  'labelexpo-southchina.com',
  'labelexpo.com',
  'labelsandlabeling.com',
  'lbhgle.com',
  'legaldive.com',
  'licenseglobal.com',
  'licensingexpo.com',
  'licensingexpochina.com',
  'licensingforretail.com',
  'licensingunlocked.com',
  'lightreading.com',
  'livderm.org',
  'livestockmalaysia.com',
  'livestockphilippines.com',
  'logisticsconference.co.uk',
  'machinetoolindonesia.com',
  'magicfashionevents.com',
  'maison-shanghai.cn',
  'manufacturingdive.com',
  'manufacturingindonesia.com',
  'manufacturingsurabaya.com',
  'marinefuels360.com',
  'marintecchina.com',
  'marintecindonesia.com',
  'maritimeonlineseries.com',
  'maritimeshows.com',
  'marketingdive.com',
  'maternalchildnutrition.com',
  'mddionline.com',
  'mdeawards.com',
  'mdmwest.com',
  'me-oto.com',
  'mebaa.aero',
  'mebaamorocco.aero',
  'mediceastafrica.com',
  'medicwestafrica.com',
  'medlab-westafrica.com',
  'medlabasia.com',
  'medlabme.com',
  'medtecchina.com',
  'medtechdive.com',
  'medtechimpact.com',
  'meetingsnet.com',
  'mefcc.com',
  'menawindpower.com',
  'miamiboatshow.com',
  'miamicosmeticsurgery.info',
  'middleeast-energy.com',
  'miff.com.my',
  'mining-indonesia.com',
  'mira-event.com',
  'mirecweek.com',
  'mogsec.com.my',
  'motorcycleshows.com',
  'multifamilydive.com',
  'multispecialtysociety.com',
  'nationalhogfarmer.com',
  'nationalrestaurantshow.com',
  'naturalandorganicasia.com',
  'naturalproductsinsider.com',
  'nbjsummit.com',
  'networkcomputing.com',
  'newhope.com',
  'newtopianow.com',
  'newyorkfarmshow.com',
  'nextyawards.com',
  'nigeria-energy.com',
  'nojitter.com',
  'northafricahealthexpo.com',
  'nrn.com',
  'ntexpo.com.br',
  'nutritioncapital.com',
  'nywomensfashionevents.com',
  'offpriceshow.com',
  'ogmtech.com',
  'oilandgas-asia.com',
  'oilgasindonesia.com',
  'omnia-health.com',
  'onegiantleap.com',
  'onrec.com',
  'osea-asia.com',
  'oshindia.com',
  'packagingdigest.com',
  'packagingdive.com',
  'packprintsummits.com',
  'painweek.org',
  'pamerindo.com',
  'panafricanleatherfair.com',
  'patientsafety-me.com',
  'paymentsdive.com',
  'pbboatshow.com',
  'pharmaconex-exhibition.com',
  'pharmapackeurope.com',
  'pharmasources.com',
  'pharmavoice.com',
  'philauto.org',
  'philenergyexpo.com',
  'physicalrehabconference.com',
  'pizzaiolo.mx',
  'plastecwest.com',
  'plasticobrasil.com.br',
  'plasticrubberthailand.com',
  'plasticsandrubberindonesia.com',
  'plasticsrubberthailand.com',
  'plasticstoday.com',
  'pmecchina.com',
  'poolspapatio.com',
  'powderandbulkshow.com',
  'powderbulksolids.com',
  'powderconferencetexas.com',
  'powdershowconnect.com',
  'premiereanaheimshow.com',
  'premieresanantonioshow.com',
  'premiereshows.com',
  'prime-journal.com',
  'printechindonesia.com',
  'proformative.com',
  'projectfashionevents.com',
  'propakasia.com',
  'propakchina.com',
  'propakmena.com',
  'propakphilippines.com',
  'prowine-singapore.com',
  'pumpsandvalves-asia.com',
  'qualifi.ai',
  'quantumbusinessnews.com',
  'rbhk-ga.com',
  'realestateforums.com',
  'remodelingdeck.com',
  'remodelingshow.com',
  'renewx.in',
  'reset-upstream.com',
  'restatexcityscape.com',
  'restatexcityscaperiyadh.com',
  'restaurant-hospitality.com',
  'restaurantbusinessonline.com',
  'restaurantdive.com',
  'retailasiaexpo.com',
  'retaildive.com',
  'retailjewellersguildawards.com',
  'routesonline.com',
  'routledge.com',
  'routledgehistoricalresources.com',
  'routledgesw.com',
  'safeindiaexpo.com',
  'safety-health-expo.co.uk',
  'saharaexpo.com',
  'saladplate.com',
  'salonduchocolat.cn',
  'salvageandwreck.com',
  'satte.in',
  'saudebusiness.com',
  'saudi-power.com',
  'saudiarabia-energy.com',
  'saudimaritimecongress.com',
  'scent-arabia.com',
  'sea-asia.com',
  'seajapan.ne.jp',
  'seatrade-cruise.com',
  'seatrade-maritime.com',
  'seatradecruisechina.com',
  'seatradecruiseevents.com',
  'seatradecruisefnb.com',
  'seatradecruiseglobal.com',
  'seatrademaritime-middleeast.com',
  'seatrademaritime-shiptechme.com',
  'seatrademaritimeclub.com',
  'seatrademaritimeevents.com',
  'selfstoragetalk.com',
  'sfeshow.com',
  'shop.airportdata.com',
  'shopfanexpo.com',
  'shopplusevent.com',
  'shp4jobs.co.uk',
  'shponline.co.uk',
  'sibconsingapore.gov.sg',
  'signistanbul.com',
  'siuf.com',
  'sjgle.com',
  'sleepandeatevent.com',
  'smartcitiesdive.com',
  'smartershows.com',
  'smworld.com',
  'socialbyinforma.com',
  'socialmediatoday.com',
  'sourcingatmagic.com',
  'southchinabeautyexpo.com',
  'specialevents.com',
  'stonexcanada.com',
  'stpeteboatshow.com',
  'studioid.com',
  'subconthailand.com',
  'suncoastboatshow.com',
  'supermarketnews.com',
  'supplychaindive.com',
  'surfaceschina.com',
  't-awards.com',
  'tackntogs.com',
  'taiwanjewelleryfair.com',
  'tandfonline.com',
  'tarsus-inc.com',
  'tarsus.com',
  'tarsusmediaops.com',
  'tarsusmedicalgroup.com',
  'tarsussupport.com',
  'taylorfrancis.com',
  'technomic.com',
  'tecnocarne.com.br',
  'tfm-japan.com',
  'thai-water.com',
  'the5gexchange.com',
  'theaestheticacademy.com',
  'theaestheticguide.com',
  'theautumnfair.com',
  'thebatteryshow.com',
  'thebatteryshow.eu',
  'theconstructionsummit.com.mx',
  'thedesignjunction.co.uk',
  'thedesignscape.co.uk',
  'thegloballicensinggroup.com',
  'thehrobserver.com',
  'thepmconference.com',
  'thermalmanagementexpo-europe.com',
  'thermalmanagementexpo.com',
  'theroofingexpo.com',
  'thinkhdi.com',
  'this.ne.jp',
  'timesaerospace.aero',
  'tissueworld.com',
  'tissueworldmagazine.com',
  'toc-connect.com',
  'toc-global-showcase.com',
  'tocevents-africa.com',
  'tocevents-americas.com',
  'tocevents-asia.com',
  'tocevents-europe.com',
  'tocworldwide.com',
  'traveltrendstoday.in',
  'truckingdive.com',
  'tu-auto.com',
  'tyrexpoasia.com',
  'uaemaritimeweek.com',
  'utilitydive.com',
  'vegascosmeticsurgery.com',
  'vegascosmeticsurgery.info',
  'vietbeautyshow.com',
  'visagecourse.com',
  'vitafoods.eu.com',
  'vitafoodsasia.com',
  'vitafoodsglobal.com',
  'vitafoodsindia.com',
  'vitafoodsinsights.com',
  'wardsauto.com',
  'waste360.com',
  'wastedive.com',
  'wasteexpo.com',
  'wastesymposium.com',
  'waterfeeds.com',
  'waterindonesiaexpo.com',
  'waterphilippinesexpo.com',
  'wave-xpo.com',
  'wealthmanagement.com',
  'westpackshow.com',
  'whatsnextinnatural.com',
  'windoorexpo.com',
  'winsightgrocerybusiness.com',
  'winsightmedia.com',
  'wocasia.cn',
  'worldaviationsafety.com',
  'worldofconcrete.com',
  'worldoflearning.com',
  'worldwhoswho.com',
  'wwettshow.com',
  'zuchex.com',
  'zephyrondemand.com',
  'arttoronto.com',
  'mefcc.com',
  'connectechasia.com',
  'automotive.knect365.com',
  'telecomstechacademy.knect365.com',
])
