import { timezonesOffsetMap } from '../constants'
import { Brand } from '../types'
import { BrandDomain } from './internalBrands'

// check if link contains 'tradepub.com' in it OR is of type 'iw-resources.informationweek.com'
export const checkIfTradepubLink = (url: string) => {
  if (!isUrlValid(url)) return

  const { hostname } = new URL(url)
  const brands = Object.values(Brand)
  const allowedHosts = brands.map(brand => `${brand}.tradepub.com`)

  return (
    allowedHosts.includes(hostname) ||
    /\b[a-z]{2,4}-resources\.[a-z]+\.com/.test(url)
  )
}

export const isUrlValid = (url: string) => {
  try {
    if (URL.canParse !== undefined) {
      return URL.canParse(url)
    }
    new URL(url)
    return true
  } catch (err) {
    return false
  }
}

export const IrisRecommendVersioning: Record<
  string,
  { stage: string; prod: string }
> = {
  version3: {
    stage: 'https://static.iris.informa.com/widgets/uat/v3/iris-recommend.js',
    prod: 'https://static.iris.informa.com/widgets/v3/iris-recommend.js',
  },
  version4: {
    stage: 'https://widgets-uat.iiris.com/iiris-recommend/v4/iris-recommend.js',
    prod: 'https://widgets.iiris.com/iiris-recommend/v4/iris-recommend.js',
  },
}

const availableVersions = Object.keys(IrisRecommendVersioning)

export const getIrisRecommendUrl = (url: string, version?: string) => {
  let selectedVersion = 'version3'
  if (typeof version === 'string' && availableVersions.includes(version)) {
    selectedVersion = version
  }

  return url.match(/(preview|staging|uat|localhost)/g)?.length
    ? IrisRecommendVersioning[selectedVersion].stage
    : IrisRecommendVersioning[selectedVersion].prod
}

export const isURLCurrentBrand = (url: string) => {
  if (typeof window === 'undefined') return false
  if (!url.includes('://')) {
    url = 'https://' + url
  }
  if (!isUrlValid(url)) return false
  if (new URL(url).hostname === new URL(window.location.href).hostname)
    return true
  return getDomainName(window.location.href) == getDomainName(url)
}

export const isInsideBrand = (url: string) => {
  if (!url.includes('://')) {
    url = 'https://' + url
  }
  if (!isUrlValid(url)) return false
  const domainName = getDomainName(url)
  return BrandDomain.has(domainName)
}

function getDomainName(inputUrl: string) {
  const hostname: string = new URL(inputUrl).hostname
  // remove any subdomains, e.g. www.example.com -> example.com
  const domain = hostname.match(
    /^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/,
  )
  if (domain) return domain[1]
  return ''
}

export const replaceTimezone = (webinarStartTime = ''): string => {
  for (const tz in timezonesOffsetMap) {
    if (webinarStartTime.includes(tz)) {
      return webinarStartTime.replace(tz, timezonesOffsetMap[tz])
    }
  }

  return webinarStartTime
}

/**
 * Converts a date and time to a short date string (e.g. Jan 23, 2024) by using en-US locale.
 * @param date A string, number or date object to be converted into short date format.
 * @returns A short date string, return empty string for undefined or null date parameter
 */
export const convertDateToShortDateString = (
  date: string | number | Date,
): string => {
  return date
    ? new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : ''
}

/**
 * Converts a date and time to a long date string (e.g. January 23, 2024) by using en-US locale.
 * @param date A string, number or date object to be converted into short date format.
 * @returns A short date string, return empty string for undefined or null date parameter
 */
export const convertDateToLongDateString = (
  date: string | number | Date,
): string => {
  return date
    ? new Date(date).toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : ''
}

export const getStyleBackgroundImage = (
  backgroundImage: string,
  params: Record<string, string>,
) => {
  if (!backgroundImage) return { backgroundImage: undefined }
  try {
    const url = new URL(backgroundImage)
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key]),
    )
    return { backgroundImage: `url("${url}")` }
  } catch (error) {
    let searchParams = '?'
    Object.keys(params).forEach(
      key => (searchParams += `${key}=${params[key]}&`),
    )
    searchParams = searchParams.slice(0, -1)
    return {
      backgroundImage: `url("${backgroundImage}${searchParams}")`,
    }
  }
}
